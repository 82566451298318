import React, { Fragment } from "react";
import InnerBanner from "../components/common/InnerBanner";
import JoinMission from "../components/homeComponents/JoinMission";
import { Link } from "react-router-dom";
import Gallery from "../components/common/Gallery";
const GalleryInner = () => {
  const breadcrumbData = [
    {
      name: "Home",
      slug: "/",
      active: true,
    },
    {
      name: "Gallery",
      slug: "/gallery",
      active: false,
    },
    {
      name: "Sports Event 2020",
      slug: "/gallery",
      active: false,
    },
  ];
  return (
    <Fragment>
      <InnerBanner
        breadcrumbData={breadcrumbData}
        title={"Sports Event 2020"}
        bgImage={"/images/innderbannerbg.jpg"}
      />
      <div className="innerPageContent">
        <div className="container">
            <Gallery/>
        <div className="row">
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery-1.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery-1.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery-1.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery-1.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery-1.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery-1.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery-1.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery-1.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery-1.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery-1.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery-1.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery-1.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
        </div>
        </div>
      
      </div>
      <JoinMission />
    </Fragment>
  );
};

export default GalleryInner;
