import React from 'react'
import styles from '../../assets/css/maintenance.module.css'
import { Link } from 'react-router-dom'
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube,  } from "react-icons/fa";
const Maintenance = () => {
  return (
    <div className={styles.errorWrapper}>
           <div className={styles.box1}>
                <img src='/images/underCon.png' alt='opps' style={{maxWidth:'400px'}}/>
           </div>
           <div className={styles.box2}>
              <h2>Page Under construction</h2>
               <p>We are working hard to make this page available for viewing.</p>
                <div className={styles.socialBox}>
                <h4>Until then, you can find us on our socials</h4>
               <ul className="footer-socials">
                          <li><Link to="/"><FaFacebook color='#e80404' size={20}/></Link></li>
                          <li><Link to="/"><FaInstagram color='#e80404' size={20}/></Link></li>
                          {/* <li><Link to="/"><FaTwitter color='#e80404' size={20}/></Link></li>
                          <li><Link to="/"><FaYoutube color='#e80404' size={20}/></Link></li> */}
                      </ul>
                </div>
             
              <Link to="/" className='button-1'>Return to Home</Link>
           </div>
    </div>
  )
}

export default Maintenance