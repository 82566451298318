import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/global.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import ScrollToTop from './components/common/ScrollToTop';

//pages
import Home from './pages/Home';
import Program from './pages/Program';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';
import GalleryInner from './pages/GalleryInner';
import Blog from './pages/Blog';
import BlogSingle from './pages/BlogSingle';
import PrivacyPolicy from './pages/PrivacyPolicy';
import PageNotFound from './pages/errors/404';
import Maintenance from './pages/errors/Maintenance';

function App() {
  return (
    <BrowserRouter>
       <Header/>
       <ScrollToTop/>
       <Routes>
          <Route path='/' exact element={<Home/>} />
          <Route path='/privacy-policy' exact element={<PrivacyPolicy/>} />
          <Route path='/programs' exact element={<Program/>} />
          <Route path='/events' exact element={<Maintenance/>} />
          <Route path='/gallery' exact element={<Gallery/>} />
          <Route path='/gallery/:slug' exact element={<GalleryInner/>} />
          <Route path='/blog' exact element={<Blog/>} />
          <Route path='/blog/:slug' exact element={<BlogSingle/>} />
          <Route path='/contact-us' exact element={<Contact/>} />
      
          <Route path='/*' exact element={<PageNotFound/>} />
       </Routes>
       <Footer/>
    </BrowserRouter>
  );
}

export default App;
