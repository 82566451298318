import React from 'react'
import { Link } from 'react-router-dom'

const InnerBanner = ({bgImage='', title='', breadcrumbData=[]}) => {
  return (
    <div className='innerPageBanner' style={{
        backgroundImage: `url(${bgImage})`,
      }}>
        <div className='bannerContent'>
            <div className='InnerBannerTitle'>
                   <h1>{title}</h1>
                   <span className='borderBottom'/>
            </div> 
        </div>
        <div className='breadcrumb'>
                <ul>
                   {
                    breadcrumbData.length > 0 && breadcrumbData.map((item)=>{
                      return  <li key={item.nam}><Link to={item.slug} className={item.active ? 'active' : '' }>{item.name}</Link></li>
                    })
                   }
                </ul>
            </div>
    </div>
  )
}

export default InnerBanner