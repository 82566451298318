import React, { Fragment } from "react";
import InnerBanner from "../components/common/InnerBanner";
import JoinMission from "../components/homeComponents/JoinMission";
import styles from '../assets/css/home.module.css'
import { FiMail, FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { FaComments } from "react-icons/fa6";
import { Link } from "react-router-dom";
const Blog = () => {
  const breadcrumbData = [
    {
      name: "Home",
      slug: "/",
      active: true,
    },
    {
      name: "Blog",
      slug: "/blog",
      active: false,
    },
  ];
  return (
    <Fragment>
      <InnerBanner
        breadcrumbData={breadcrumbData}
        title={"Blog"}
        bgImage={"/images/innderbannerbg.jpg"}
      />
      <div className="innerPageContent">
        <div className="container">
        <div className={styles.postList}>
            <div className="row">
                <div className="col-md-4">
                   <div className={styles.postItem}>
                      <div className={styles.postThumb}>
                         <img src="/images/postth1.jpg" alt="post_thumb"/>
                         <div className={styles.postDate}>
                            20 May 
                         </div>
                      </div>
                      <div className={styles.postExcerpt}>
                          <div className={styles.postExcerptHead}>
                              <ul>
                              <li><FiMail size={18} color="#fcad30"/> Admin</li>
                                  <li><FaComments size={18} color="#fcad30"/> Comments</li>
                              </ul>
                          </div>
                          <h3>Our donation is hope for poor childrens</h3>
                      </div>
                      <div className={styles.postActions}>
                           <Link to={'/'}> <FiChevronRight size={20} color="#7e7e7e"/>  Read More</Link>
                      </div>
                   </div>
                </div>
                <div className="col-md-4">
                   <div className={styles.postItem}>
                      <div className={styles.postThumb}>
                         <img src="/images/postth2.jpg" alt="post_thumb"/>
                         <div className={styles.postDate}>
                            20 May 
                         </div>
                      </div>
                      <div className={styles.postExcerpt}>
                          <div className={styles.postExcerptHead}>
                              <ul>
                              <li><FiMail size={18} color="#fcad30"/> Admin</li>
                                  <li><FaComments size={18} color="#fcad30"/> Comments</li>
                              </ul>
                          </div>
                          <h3>Our donation is hope for poor childrens</h3>
                      </div>
                      <div className={styles.postActions}>
                           <Link to={'/'}> <FiChevronRight size={20} color="#7e7e7e"/>  Read More</Link>
                      </div>
                   </div>
                </div>
                <div className="col-md-4">
                   <div className={styles.postItem}>
                      <div className={styles.postThumb}>
                         <img src="/images/postth3.jpg" alt="post_thumb"/>
                         <div className={styles.postDate}>
                            20 May 
                         </div>
                      </div>
                      <div className={styles.postExcerpt}>
                          <div className={styles.postExcerptHead}>
                              <ul>
                                  <li><FiMail size={18} color="#fcad30"/> Admin</li>
                                  <li><FaComments size={18} color="#fcad30"/> Comments</li>
                              </ul>
                          </div>
                          <h3>Our donation is hope for poor childrens</h3>
                      </div>
                      <div className={styles.postActions}>
                           <Link to={'/'}> <FiChevronRight size={20} color="#7e7e7e"/>  Read More</Link>
                      </div>
                   </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                   <div className={styles.postItem}>
                      <div className={styles.postThumb}>
                         <img src="/images/postth1.jpg" alt="post_thumb"/>
                         <div className={styles.postDate}>
                            20 May 
                         </div>
                      </div>
                      <div className={styles.postExcerpt}>
                          <div className={styles.postExcerptHead}>
                              <ul>
                              <li><FiMail size={18} color="#fcad30"/> Admin</li>
                                  <li><FaComments size={18} color="#fcad30"/> Comments</li>
                              </ul>
                          </div>
                          <h3>Our donation is hope for poor childrens</h3>
                      </div>
                      <div className={styles.postActions}>
                           <Link to={'/'}> <FiChevronRight size={20} color="#7e7e7e"/>  Read More</Link>
                      </div>
                   </div>
                </div>
                <div className="col-md-4">
                   <div className={styles.postItem}>
                      <div className={styles.postThumb}>
                         <img src="/images/postth2.jpg" alt="post_thumb"/>
                         <div className={styles.postDate}>
                            20 May 
                         </div>
                      </div>
                      <div className={styles.postExcerpt}>
                          <div className={styles.postExcerptHead}>
                              <ul>
                              <li><FiMail size={18} color="#fcad30"/> Admin</li>
                                  <li><FaComments size={18} color="#fcad30"/> Comments</li>
                              </ul>
                          </div>
                          <h3>Our donation is hope for poor childrens</h3>
                      </div>
                      <div className={styles.postActions}>
                           <Link to={'/'}> <FiChevronRight size={20} color="#7e7e7e"/>  Read More</Link>
                      </div>
                   </div>
                </div>
                <div className="col-md-4">
                   <div className={styles.postItem}>
                      <div className={styles.postThumb}>
                         <img src="/images/postth3.jpg" alt="post_thumb"/>
                         <div className={styles.postDate}>
                            20 May 
                         </div>
                      </div>
                      <div className={styles.postExcerpt}>
                          <div className={styles.postExcerptHead}>
                              <ul>
                                  <li><FiMail size={18} color="#fcad30"/> Admin</li>
                                  <li><FaComments size={18} color="#fcad30"/> Comments</li>
                              </ul>
                          </div>
                          <h3>Our donation is hope for poor childrens</h3>
                      </div>
                      <div className={styles.postActions}>
                           <Link to={'/'}> <FiChevronRight size={20} color="#7e7e7e"/>  Read More</Link>
                      </div>
                   </div>
                </div>
            </div>
            <div className="pagination">
                <ul>
                    <li><a href="#" className="active"><FiChevronLeft/></a></li>
                    <li><a href="#" className="active">01</a></li>
                    <li><a href="#">02</a></li>
                    <li><a href="#">03</a></li>
                    <li><a href="#" className="active"><FiChevronRight/></a></li>
                </ul>
            </div>
         </div>
        </div>
      </div>
      <JoinMission />
    </Fragment>
  );
};

export default Blog;
