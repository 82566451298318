// src/GalleryThumbnail.js
import React from 'react';

const GalleryThumbnail = ({ index, photo, openLightbox }) => {
  return (
    <div className="thumbnail" onClick={() => openLightbox(index)}>
      <img src={photo.src} alt={photo.caption} />
    </div>
  );
};

export default GalleryThumbnail;