import React from 'react'
import styles from '../../assets/css/modal.module.css'
const MyModal = ({isOpen, closeModal, children}) => {
    if (!isOpen) return null;
  return (
    <div onClick={closeModal} className={styles.modalWrapper}>
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.modalContainer}>
         {children}
    </div>
  </div>
  )
}

export default MyModal