import React,{useState} from 'react'


import { FaFacebook, FaInstagram  } from "react-icons/fa";
import { FiPhone, FiMail, FiMapPin, FiChevronUp  } from "react-icons/fi";


import { Link } from 'react-router-dom';
const Footer = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://maxxsocialwelfare.org/phpmail/sendMail.php', {
        method: 'POST',
        body: JSON.stringify({ email }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
          setEmail('');
          alert('Thanks for Subscribe !');
      } else {
        alert('Email sending failed!');
      }
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };
  return (
      <footer>
           <div className='container'>
               <div className='row'>
                 <div className='col-md-3'>
                      <div className='footer-widget'>
                          <div className='footer_brand'>
                            <img src='/images/logo.png' alt='logo'/>
                          </div>
                          <p>We strive to empower communities to ensure sustainable and holistic interventions in the fields of health/fitness, sports, education, and employment. Working at grassroot level, we strive to create positive changes in the lives of underprivileged children, their families and communities.</p>
                          <ul className="footer-socials">
                          <li><Link to="/"><FaFacebook color='#e80404' size={20}/></Link></li>
                          <li><Link to="/"><FaInstagram color='#e80404' size={20}/></Link></li>

                          {/* <li><Link to="/"><FaTwitter color='#e80404' size={20}/></Link></li>
                          <li><Link to="/"><FaYoutube color='#e80404' size={20}/></Link></li> */}
                      </ul>
                      </div>
                   
                 </div>
                 <div className='col-md-2'>
                    <div className='footer-widget widget-2'>
                          <div className='footer-widget-title'>
                             <h4>Quick links</h4>
                           </div>
                           <ul className='footer-menu'>
                              <li><Link to="/">Causes</Link></li>
                              <li><Link to="/">About</Link></li>
                              <li><Link to="/">FAQ</Link></li>
                              <li><Link to="/">Our Team</Link></li>
                              <li><Link to="/">News</Link></li>
                           </ul>
                    
                  </div>
                 </div>
                 <div className='col-md-3'>
                     <div className='footer-widget widget-3'>
                          <div className='footer-widget-title'>
                             <h4>Contacts Info</h4>
                           </div>
                           <ul className='contactList'>
                              <li className='contactListItem'>
                               
                                <div className='fIcon'>
                                    <FiPhone color='#fff' size={22}/>
                                 </div>
                                 <p><strong>Call us</strong><br/>+91 70459 54450</p>
                               
                              </li>
                              <li className='contactListItem'>
                              
                                <div className='fIcon'>
                                    <FiMail color='#fff' size={22}/>
                                 </div>
                                 <p><strong>Mail Us</strong><br/>info@maxxsocialwelfare.org</p>
                                
                              </li>
                                <li className='contactListItem'>
                              
                                <div className='fIcon'>
                                    <FiMapPin color='#fff' size={22}/>
                                 </div>
                                 <p><strong>Visit Us</strong><br/>
Near Chip and Dale School, Patanwala Estate, Lal Bahadur Shastri Marg, opp. Shreyas Cinema, Ghatkopar West, Mumbai, Maharashtra 400086</p>
                                
                              </li>
                           
                              
                           </ul>
                      </div> 
                 </div>
                 <div className='col-md-4'>
                     <div className='footer-widget widget-4'>
                     <div className='footer-widget-title'>
                             <h4>Subscribe to Our Newsletter</h4>
                           </div>
                           <p>Get the latest updates about our initiatives</p>
                           <form className='newLatterForm' onSubmit={handleSubmit}>
                                <input 
                                  type="email" 
                                  name="email" 
                                  placeholder="Enter email.." 
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  />
                                <button type='submit'>Submit</button>
                           </form>
                     </div>
                 </div>
               </div>

           </div>
           <div className="footer-bottom">
                 <button  onClick={() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }}><FiChevronUp/></button>
                <p>© Copyright 2023 by maxxsocialwelfare.com  | <Link to="/privacy-policy">Privacy Policy</Link> </p>
           </div>
        
      </footer>
  )
}

export default Footer