import React, { useState } from 'react';
import GalleryThumbnail from './GalleryThumbnail';
import { Modal } from 'react-bootstrap';
import MyModal from './MyModal';


const Gallery = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);
const photos = [
  {
    src: '/images/gallery-1.jpg',
    caption: 'Image 1',
  },
  {
    src: '/images/pe-1.jpg',
    caption: 'Image 2',
  },
  {
    src: '/images/pe-1.jpg',
    caption: 'Image 2',
  },
];
  const openLightbox = (index) => {
    setSelectedIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setSelectedIndex(0);
    setLightboxOpen(false);
  };

  const goToPrevious = () => {
    if (selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    }
  };

  const goToNext = () => {
    if (selectedIndex < photos.length - 1) {
      setSelectedIndex(selectedIndex + 1);
    }
  };

  return (
    <div>
      <div className="gallery">
        {photos.map((photo, index) => (
          <GalleryThumbnail
            key={index}
            index={index}
            photo={photo}
            openLightbox={openLightbox}
          />
        ))}
      </div>
      <MyModal>
        
      </MyModal>
      {/* <Modal show={lightboxOpen} onHide={closeLightbox}>
        <Modal.Body>
            <button onClick={goToPrevious}>Prev</button>
            <button onClick={goToNext}>Next</button>
          <img src={photos[selectedIndex].src} alt={photos[selectedIndex].caption} />
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default Gallery;
