import React, { Fragment } from "react";
import InnerBanner from "../components/common/InnerBanner";
import JoinMission from "../components/homeComponents/JoinMission";
import { Link } from "react-router-dom";

const Gallery = () => {
  const breadcrumbData = [
    {
      name: "Home",
      slug: "/",
      active: true,
    },
    {
      name: "Gallery",
      slug: "/gallery",
      active: false,
    },
  ];
  return (
    <Fragment>
      <InnerBanner
        breadcrumbData={breadcrumbData}
        title={"Gallery"}
        bgImage={"/images/innderbannerbg.jpg"}
      />
      <div className="innerPageContent">
        <div className="container">
        <div className="row justify-content-center">
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery/g-1.jpeg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>BGBC creative of guest faculty (Nitin Pawar)</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery/g-2.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Blue Giving Tuesday-Inspired Event</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery/g-3.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Blue Giving Tuesday-Inspired Event</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery/g-4.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Creative for womens day 1</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery/g-5.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery/g-6.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery/g-7.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery/g-8.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Sports Event 2020</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery/g-9.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Little Flower High School</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery/g-10.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Mahatma Gandhi school, dehu road 2</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery/g-11.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Mahatma Gandhi school, dehu road 4</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery/g-12.jpeg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>Powai English High School</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              <div className="col-6 col-md-3 col-sm-6">
                 <div className="galleryItem">
                    <Link to={'/'}>
                    <img src="/images/gallery/g-13.jpg" alt="img"/>
                     <div className="galleryCaption">
                         <h4>R K Public School</h4>
                     </div>
                    </Link>
                 </div>
              </div>
              
        </div>
        </div>
      
      </div>
      <JoinMission />
    </Fragment>
  );
};

export default Gallery;
